import { Link, PageLayout, Typography } from "@components";
import React from "react";
import * as styles from "./index.module.scss";
import { graphql } from "gatsby";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import { BackgroundShapes } from "@components/BackgroundShapes/BackgroundShapes";
import Button from "@components/Button_new/Button";
import { pinkText } from "@components/Typography/Typography";
import { PinkOvals0, PinkOvals1 } from "@assets/Background";
import { StaticImage } from "gatsby-plugin-image";
import { SectionTitle } from "@components/SectionTitle/SectionTitle";
import { commitmentData, perksData } from "./_data";
import { useWindowSize } from "@helpers/Hooks";

type CareersPageProps = {
	data: {
		jobs: {
			nodes: {
				id: string;
				title: string;
				path: {
					alias: string;
					pid: string | number;
				};
				relationships: {
					field_department: {
						name: string;
					};
					field_job_type: {
						name: string;
					};
				};
				field_location: string;
			}[];
		};
	};
};

export const query = graphql`
	{
		jobs: allNodeJob {
			nodes {
				id
				title
				path {
					alias
					pid
				}
				relationships {
					field_department {
						name
					}
					field_job_type {
						name
					}
				}
				field_location
			}
		}
	}
`;

type JobData = {
	title: string;
	location: string;
	department: string;
	link: string;
};
export default function CareersPage({ data }: CareersPageProps) {
	const { jobs } = data;
	const jobList = jobs.nodes.map((node) => {
		return {
			department: node.relationships.field_department.name,
			title: node.title,
			location: node.field_location,
			link: "/" + node.path.alias.slice(1) + "/" + node.path.pid,
		};
	});

	return (
		<PageLayout
			seoOptions={{ type: "PREDEFINED", pageName: "careers" }}
			defaultHeader="light"
		>
			<Hero />
			<CoreValue />
			<JobOpenings jobs={jobList} />
			<OurPerks />
			<Commitment />
		</PageLayout>
	);
}

function Hero() {
	const ScrollToJobOpenings = () => {
		const element = document.getElementById("job-opening-section");
		element?.scrollIntoView({ block: "center", behavior: "smooth" });
	};
	return (
		<BackgroundFadeContainer type="solidLightPink" withHeaderPadding>
			<BackgroundShapes
				leftShape={{
					shape: <PinkOvals1 />,
					xOffset: -120,
					yOffset: -290,
				}}
				rightShape={{
					shape: <PinkOvals0 />,
					xOffset: 0,
					yOffset: 50,
				}}
			>
				<div className={styles.careersHeroContainer}>
					<Typography variant="HERO">
						{pinkText`Join us to accelerate the ${"next generation"} of therapeutics.`}
					</Typography>
					<Typography variant="SUBTITLE">
						The best work happens when you feel free to be your best
						self. Genemod is committed to building a workplace that
						fosters inclusivity alongside productivity.
					</Typography>
					<Button
						className={styles.careersHeroButton}
						onClick={ScrollToJobOpenings}
					>
						See openings
					</Button>
				</div>
			</BackgroundShapes>
		</BackgroundFadeContainer>
	);
}

function CoreValue() {
	let { isMobile } = useWindowSize();
	return (
		<BackgroundFadeContainer type="solidBlue">
			<SectionTitle
				title="Our core values"
				subtitle="These are the values that guide our business, our product
                development, and our brand. As our company continues to evolve
                and grow, these seven values remain constant"
				titleWidth={338}
				subtitleWidth={920}
				textColor="ui-01"
				marginTop={120}
				marginBottom={60}
				marginTopTablet={120}
				marginBottomTablet={60}
				marginTopMobile={80}
			/>
			{isMobile ? (
				<StaticImage
					loading="eager"
					src="../about-us/images/core-values-mobile.png"
					alt="genemod core values"
					style={{ marginBottom: 80 }}
				/>
			) : (
				<StaticImage
					loading="eager"
					src="../about-us/images/core-values.png"
					alt="genemod core values"
					style={{ marginBottom: 96 }}
				/>
			)}
		</BackgroundFadeContainer>
	);
}

function JobOpenings({ jobs }: { jobs: JobData[] }) {
	return (
		<BackgroundFadeContainer type="solidLightPink">
			<SectionTitle
				title="Current job openings"
				titleWidth={455}
				textColor="text-dark"
				marginTop={120}
				marginBottom={60}
				marginTopTablet={120}
				marginBottomTablet={60}
				marginTopMobile={80}
			/>
			<div
				id="job-opening-section"
				className={styles.cardWrapper}
				style={{
					width: "100%",
				}}
			>
				{jobs.map((job) => (
					<JobCard key={job.title} {...job} />
				))}
			</div>
		</BackgroundFadeContainer>
	);
}

function JobCard({ title, department, location, link }: JobData) {
	return (
		<Link to={link} internal>
			<div className={styles.jobCard}>
				<div className={styles.jobTitleAndPos}>
					<Typography variant="SUBTITLE" color="text-dark">
						{title + " [Closed]"}
					</Typography>
					<Typography
						className={styles.jobPosition}
						color="medium-pink"
						variant="CAPTION"
					>
						{department}
					</Typography>
				</div>
				<Typography variant="SUBTITLE" color="cloudy-blue">
					{location}
				</Typography>
			</div>
		</Link>
	);
}

function OurPerks() {
	const PerkCard = (perk: { img: React.ReactNode; label: string }) => (
		<div className={styles.perkCard}>
			{perk.img}
			<Typography variant="SUBTITLE" style={{ marginTop: 20 }}>
				{perk.label}
			</Typography>
		</div>
	);
	return (
		<BackgroundFadeContainer type="solidBlue">
			<SectionTitle
				title="Our perks"
				titleWidth={212}
				textColor="ui-01"
				marginTop={120}
				marginBottom={60}
				marginTopTablet={120}
				marginBottomTablet={60}
				marginTopMobile={80}
			/>
			<div
				className={styles.cardWrapper}
				style={{
					flexWrap: "wrap",
					flexDirection: "row",
					justifyContent: "center",
				}}
			>
				{perksData.map((perk) => (
					<PerkCard key={perk.label} {...perk} />
				))}
			</div>
		</BackgroundFadeContainer>
	);
}

function Commitment() {
	const CommitmentCard = (commitment: {
		title: string;
		description: string;
	}) => (
		<div className={styles.commitmentCard}>
			<div className={styles.commitmentCardContent}>
				<Typography
					variant="HEADER4"
					color="marine"
					center
					style={{ marginBottom: 20 }}
				>
					{commitment.title}
				</Typography>
				<Typography variant="SUBHEADERDESC" color="text-dark">
					{commitment.description}
				</Typography>
			</div>
		</div>
	);
	return (
		<BackgroundFadeContainer type="solidLightPink">
			<BackgroundShapes
				leftShape={{
					shape: (
						<div style={{ transform: "rotate(-150deg)" }}>
							<PinkOvals1 />
						</div>
					),
					xOffset: -100,
					yOffset: 50,
				}}
				rightShape={{
					shape: (
						<div style={{ transform: "rotate(-25deg)" }}>
							<PinkOvals0 />
						</div>
					),
					xOffset: 190,
					yOffset: 620,
				}}
			>
				<SectionTitle
					title={pinkText`Our commitment to\n${"diversity, inclusion & belonging"}`}
					titleWidth={680}
					textColor="text-dark"
					marginTop={120}
					marginBottom={60}
					marginTopTablet={120}
					marginBottomTablet={60}
					marginTopMobile={80}
				/>
				<div className={styles.cardWrapper}>
					{commitmentData.map((commitment) => (
						<CommitmentCard
							key={commitment.title}
							{...commitment}
						/>
					))}
				</div>
			</BackgroundShapes>
		</BackgroundFadeContainer>
	);
}
